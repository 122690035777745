import React from 'react';
import ApiErrorProvider from '../lib/context-providers/api-error-context';
import CustomSettingsProvider from '../lib/context-providers/custom-settings-context';
import EventSettingsProvider from '../lib/context-providers/event-settings-context/event-settings-context';
import MediaQueryContextProvider from '../lib/context-providers/media-query-provider';
import AppRouter from './Router';
import { RoutesConfig } from '../config/routes';
import AuthProvider from '../lib/context-providers/auth-context';
import ErrorModal from './shared/ErrorModal';
import PasswordlessAuthProvider from '../lib/context-providers/paswordless-context/passwordless-context';
import CookieNotificationWrapper from './shared/CookieNotificationWrapper/CookieNotificationWrapper';
import { AppConfigurationProvider } from '../lib/context-providers/app-config-context';
import { AppConfigurator } from '../lib/services/app-configuration/AppConfigurator';
import { AccountContextProvider } from '../lib/context-providers/account-context';

type Props = {
  routes: RoutesConfig
}

function App(props: Props): JSX.Element {

  return (
    <div className='App'>
      <div className='router-wrapper h-screen w-full mx-auto overflow-auto' data-id="app">
        <AppRouter {...props} />
        <ApiErrorProvider>
          <ErrorModal/>
        </ApiErrorProvider>
        {AppConfigurator.isPassportProfile && <CookieNotificationWrapper />}
      </div>
    </div>
  );
}

export default (props: Props): JSX.Element => {

  if (AppConfigurator.isPassportProfile) {

    return (
      <AppConfigurationProvider>
        <AccountContextProvider>
          <AuthProvider>
            <PasswordlessAuthProvider>
              <MediaQueryContextProvider>
                <CustomSettingsProvider>
                  <EventSettingsProvider>
                    <App {...props} />
                  </EventSettingsProvider>
                </CustomSettingsProvider>
              </MediaQueryContextProvider>
            </PasswordlessAuthProvider>
          </AuthProvider>
        </AccountContextProvider>
      </AppConfigurationProvider>
    );
  }

  return <App {...props} />;
};
