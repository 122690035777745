import { NavLink } from 'react-router-dom';
import {
  AGENDA_PATH,
  SHOWCASE_PATH,
  SUPPORT_PATH,
  WELCOME_PATH,
  SESSION_PATH,
  SPEAKERS_PATH,
  REGISTRATION_PATH,
  ROOT,
  REPLAYS_PATH
} from '../config/routes/paths';
import { EventPrivateDetailsResponseType } from '../lib/api';
import { CustomPageProps, defaultPagePathByType, ExternalLinkPageType, PassportPageType } from '../lib/api/custom-page.types';

import { useEventSettings, EventSettingsContextType, EventPrivateSettingsType } from '../lib/context-providers/event-settings-context/event-settings-context';
import { truncateString } from '../lib/helpers/text-formatters';
import { useEventPublicInfo } from './api/public/use-event-public-details';
import { extractCustomPages, extractDefaultPages } from './use-custom-routes';
import { getFirstPathSegmentFromLocation, normalizeRoutePath } from '../lib/helpers/urlHelper';
import { AccountContextType, useAccountContext } from '../lib/context-providers/account-context';

const ITEMS_ORDERED = [ WELCOME_PATH, AGENDA_PATH, SESSION_PATH, SHOWCASE_PATH, SPEAKERS_PATH, SUPPORT_PATH, REPLAYS_PATH ];
export const MIN_DESKTOP_LABEL_LENGTH = 10;
export const MAX_DESKTOP_LABEL_LENGTH = 60;

export type ConfigItemType = {
  className?: string;
  activeClassName?: string;
  to: string;
  label: string;
  tooltipPosition?: string;
  tooltip?: string;
  reload?: boolean;
  exact?: boolean;
} & Partial<NavLink>;

/**
 * Compose NavItem base config (props)
 */
export const getConfigByPath = (path: string, data: EventPrivateDetailsResponseType & EventPrivateSettingsType): ConfigItemType | null => {
  const to = path;
  switch (path) {
  case WELCOME_PATH:
    return data.welcomeEnabled ? { to, label: data.welcomeLabel || 'Welcome' } : null;
  case AGENDA_PATH:
    return data.agendaEnabled ? { to, label: data.agendaLabel || 'My Schedule' } : null;
  case SESSION_PATH:
    return data.sessionEnabled ? { to, label: data.sessionLabel || 'General sessions' } : null;
  case SHOWCASE_PATH:
    return data.showcaseEnabled ? { to, label: data.showcaseLabel || 'Showcase' } : null;
  case SPEAKERS_PATH:
    return data.speakersEnabled ? { to, label: data.speakersLabel || 'Speakers' } : null;
  case SUPPORT_PATH:
    return data.supportEnabled ? { to, label: data.supportLabel || 'Support' } : null;
  case REPLAYS_PATH:
    return data.replaysEnabled ? { to, label: data.replaysLabel || 'Replays' } : null;
  default:
    return null;
  }
};

type EnhancerType<T> = (item: T) => T;

export const addItemLabelTooltip: EnhancerType<ConfigItemType> = (item, position = 'bottom') => {
  return item ? { ...item, 'data-tooltip': item.label, 'data-tooltip-position': position } : item;
};

export const truncateDesktopLabel = (label: string): string => truncateString(label, MAX_DESKTOP_LABEL_LENGTH);

export const truncateItemLabel: EnhancerType<ConfigItemType> = (item): ConfigItemType => {
  return item ? { ...item, label: truncateDesktopLabel(item.label) } : item;
};

// add tooltip to the label with length > 10 chars
export const applyDesktopTooltipEnhancer: EnhancerType<ConfigItemType | null> = (item) => {
  return item && item.label.length > MIN_DESKTOP_LABEL_LENGTH ? addItemLabelTooltip(item) : item;
};

// truncate label with length more than 60 chars
export const applyDesktopTruncateEnhancer: EnhancerType<ConfigItemType | null> = (item) => {
  return item && item.label.length > MAX_DESKTOP_LABEL_LENGTH ? truncateItemLabel(item) : item;
};

const filterAndSortByDefaultOrder = (items: ConfigItemType[], pathPrefix?: string): ConfigItemType[] => {
  return ITEMS_ORDERED.reduce((orderedItems, pathKey) => {
    const item = items?.find(({ to }) => to === normalizeRoutePath(pathKey, pathPrefix));
    return item ? [ ...orderedItems, item ] : orderedItems;
  }, [] as ConfigItemType[]);
};

const customPagesToNavItems = (pages: CustomPageProps[] = [], isPublicPages: boolean, pathPrefix?: string): ConfigItemType[] => {
  if (!pages.length) return [];

  const isPageEnabledKey = isPublicPages ? 'publicEnabled' : 'enabled';

  const defaultPagesWithOrder = extractDefaultPages(pages)
    .filter(page => page[isPageEnabledKey])
    .map(({ label, type, order }) => ({
      label,
      to: normalizeRoutePath(defaultPagePathByType[type], pathPrefix),
      order,
      reload: type === PassportPageType.SHOWCASE
    }));

  const customPagesWithOrder = extractCustomPages(pages)
    .filter(page => page[isPageEnabledKey])
    .map((item) => {
      const { type, label, path, order } = item;
      const externalLinkPage = item as ExternalLinkPageType;
      if (type === PassportPageType.EXTERNAL_LINK) {
        return { label: label, to: { pathname: externalLinkPage.externalLink ?? '' }, target: '_blank', order };
      }
      return { label: label, to: normalizeRoutePath(path, pathPrefix), order };
    }) as ConfigItemType[];

  return ([ ...filterAndSortByDefaultOrder(defaultPagesWithOrder, pathPrefix), ...customPagesWithOrder ] as (ConfigItemType & { order: number })[])
    .slice()
    .sort((a, b) => (a.order || 0) - (b.order || 0))
    .map(({ order, ...rest }) => rest)
  ;
};


export default function useNavBarItemsConfig(isDesktop: boolean | void, isAuthenticated: boolean): ConfigItemType[] | null {
  const accContext = useAccountContext() as AccountContextType;
  const path = getFirstPathSegmentFromLocation();
  const pathPrefix = accContext.isAccountSubdomain ? path : '';
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const { data: eventPublicInfo } = useEventPublicInfo(isAuthenticated);

  let items = customPagesToNavItems(((eventSettings || eventPublicInfo)?.customPages), !isAuthenticated, pathPrefix);

  if (!isAuthenticated) {
    items = [
      ...items,
      eventPublicInfo?.registrationEnabled && {
        label: 'Registration',
        to: normalizeRoutePath(REGISTRATION_PATH, pathPrefix),
        reload: false
      },
      {
        label: eventPublicInfo?.signInPageLabel || 'Sign In',
        to: normalizeRoutePath(ROOT, pathPrefix),
        reload: false,
        exact: true
      }
    ].filter(Boolean) as ConfigItemType[];
  }

  if (isDesktop) {
    return items
      // add tooltips
      .map(applyDesktopTooltipEnhancer)
      // truncate labels
      .map(applyDesktopTruncateEnhancer) as ConfigItemType[]
    ;
  }

  return items;
}
