import { CustomPageProps, defaultPagePathByType } from '../lib/api/custom-page.types';
import { useEventPrivateInfo } from './api/protected/use-event-private-details';
import { isDefaultPage } from './use-custom-routes';
import { AccountContextType, useAccountContext } from '../lib/context-providers/account-context';
import { getFirstPathSegmentFromLocation, normalizeRoutePath } from '../lib/helpers/urlHelper';


export default function useDefaultRoutePage(): string {
  const accContext = useAccountContext() as AccountContextType;
  const { data } = useEventPrivateInfo();
  const pathPrefix = accContext.isAccountSubdomain ? getFirstPathSegmentFromLocation() : '';
  const normalizedEmptyRoute = normalizeRoutePath('', pathPrefix);

  if (!data || !data.customPages) return normalizedEmptyRoute;

  const pagebyLandingPageProp = data.customPages.find(route => route.landingPage && route.enabled) as CustomPageProps;
  if (pagebyLandingPageProp) {
    return isDefaultPage(pagebyLandingPageProp)
      ? defaultPagePathByType[pagebyLandingPageProp.type]
      : `/${pagebyLandingPageProp.path}`;
  }

  const pagebyEnableProp = data.customPages.find(route => route.enabled) as CustomPageProps;
  if (pagebyEnableProp) {
    return isDefaultPage(pagebyEnableProp)
      ? defaultPagePathByType[pagebyEnableProp.type]
      : `/${pagebyEnableProp.path}`;
  }

  return normalizedEmptyRoute;
}
