import React, { Fragment, useEffect } from 'react';
import { PrivateMeetingSessionDetailsListResponseType } from '../../../../lib/api';
import { PrivateMeetingSessionDetailsResponseType } from '../../../../lib/api';
import useSetKeyIdClass from '../../../../hooks/use-set-key-id-class';
import { IGroupedMeetingsList } from '../GridLayout/index';
import { getActiveTracks } from '../helpers';
import { useCarousel } from './useCarousel';
import { CardComponent } from './Card';
import { Carousel } from './Carousel';
import { EventType } from '../LayoutWrapper';
import './CarouselWrapper.scss';
import './Card.scss';

export type PrivateMeetingSessionList = PrivateMeetingSessionDetailsListResponseType;

interface ICarouselWrapper {
  event: EventType;
  selectedDate: string;
  showAllDates?: boolean;
  showPastMeetings: boolean;
  isCarouselViewByDay?: boolean;
  meetings: PrivateMeetingSessionDetailsResponseType[];
  searchedValue: string;
  setFilteredMeetingsCount: (meetingsNumber: number) => void;
  eventToken: string;
  transformedTimeZone: string;
}

export interface ICarouselSection {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
  activeMeetings: PrivateMeetingSessionList;
}

interface ICarouseBox {
  event: EventType;
  selectedDate?: string;
  groupByDay?: boolean;
  meetings: PrivateMeetingSessionDetailsResponseType[];
  eventToken: string;
  transformedTimeZone: string;
}

const Styles = {
  blockRow: 'w-full flex flex-row justify-between',
  blockCol: 'flex items-center flex-col',
  headerCssClass: 'font-bold text-left md:text-right',
};

const className = 'carouse-layout-session';
const classNameSessionHeaderRight = `${className}--header-right`;
const classNameSessionTableHeaderLeft = `${className}--table-header-left`;

const renderSeparateLine = (con: boolean): JSX.Element | null => { return con ? <hr className='my-8 w-full' /> : null; };

const count = 1;

export const CarouselBox = ({
  meetings,
  event,
  eventToken,
  groupByDay = false,
  transformedTimeZone
}: ICarouseBox): JSX.Element | null => {
  const classNameEventDetails = 'editable-' + useSetKeyIdClass() + '-event-details';
  const classNameEventDetailsDate = `${classNameEventDetails}--date`;

  const tracks = getActiveTracks(meetings);
  const inc = 1;

  const mobLimit = 2;
  const tableLimit = 3;
  const laptopLimit = 4;

  const screenBreakpoints = {
    small: 600,
    medium: 768,
    large: 1024,
  };

  const countSliders = {
    mobLimit: 2,
    tableLimit: 3,
    laptopLimit: 4
  };

  const responsive = {
    [screenBreakpoints.small]: { items: mobLimit },
    [screenBreakpoints.medium]: { items: tableLimit },
    [screenBreakpoints.large]: { items: laptopLimit },
  };

  if (groupByDay) {
    return (
      <div className='w-full'>
        {meetings.length
          ? (
            <div className={Styles.blockRow}>
              <Carousel
                responsiveSettings={responsive}
                countSliders={countSliders}
                screenBreakpoints={screenBreakpoints}
              >
                {meetings.map((m, i) => (
                  <CardComponent
                    index={i+inc}
                    meeting={m} event={event}
                    key={`${i} + ${m.meetingId}`}
                    eventToken={eventToken}
                    transformedTimeZone={transformedTimeZone}
                  />
                ))}
              </Carousel>
            </div>
          )
          : null
        }
        { renderSeparateLine(Boolean(meetings.length)) }
      </div>
    );
  }

  return (
    <div className='w-full'>
      {
        (tracks || []).map((track: string) => {
          const featured = meetings.filter(m => m.track === track && m.featured);
          const noFeatured = meetings.filter(m => m.track === track && !m.featured);
          const isTrack = Boolean(track);
          const sessions = featured.length + noFeatured.length;
          return (
            <Fragment key={track}>
              {
                isTrack
                  ? (
                    <div key={track} role='columnheader' className={`${classNameSessionTableHeaderLeft} pb-8 flex items-center`}>
                      <p className={`${classNameEventDetailsDate} font-size-16px ${Styles.headerCssClass} flex justify-start md:justify-end mr-3`}>
                        {track}
                      </p>
                      <span
                        style={{
                          fontSize: '10px',
                          lineHeight: '1.2rem',
                        }}
                        className={classNameSessionHeaderRight + 'flex whitespace-no-wrap'}
                      >
                        {`${sessions} ${sessions === count ? 'session' : 'sessions'}`}
                      </span>
                    </div>
                  )
                  : null
              }
              <div className={Styles.blockCol}>
                {featured.map((m, i) => (
                  <CardComponent
                    meeting={m}
                    index={i+inc}
                    event={event}
                    key={`${i} + ${m.meetingId}`}
                    eventToken={eventToken}
                    transformedTimeZone={transformedTimeZone}
                  />
                ))}
              </div>
              {noFeatured.length
                ? (
                  <div className={Styles.blockRow}>
                    <Carousel
                      responsiveSettings={responsive}
                      countSliders={countSliders}
                      screenBreakpoints={screenBreakpoints}
                    >
                      {noFeatured.map((m, i) => (
                        <CardComponent
                          index={i+inc}
                          meeting={m} event={event}
                          key={`${i} + ${m.meetingId}`}
                          eventToken={eventToken}
                          transformedTimeZone={transformedTimeZone}
                        />
                      ))}
                    </Carousel>
                  </div>
                )
                : null
              }
              { renderSeparateLine(Boolean(noFeatured.length || featured.length)) }
            </Fragment>
          );
        })
      }
    </div>
  );
};

const CarouselWrapperByDay = (props: ICarouselWrapper): JSX.Element => {
  const {
    event,
    meetings,
    selectedDate,
    isCarouselViewByDay,
    showPastMeetings,
    searchedValue,
    setFilteredMeetingsCount,
    eventToken,
    transformedTimeZone,
  } = props;

  const {
    featuredMeetings,
    noFeaturedMeetings
  } = useCarousel({ meetings, selectedDate, showPastMeetings, searchedValue, groupByDay: true });
  const sessions = featuredMeetings.length + noFeaturedMeetings.length;
  const isSessionsExist = Boolean(sessions);
  const classNameEventDetails = 'editable-' + useSetKeyIdClass() + '-event-details';
  const classNameEventDetailsDate = `${classNameEventDetails}--date`;

  useEffect(() => {
    setFilteredMeetingsCount(sessions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  return (
    <div>
      {
        isCarouselViewByDay
          ? (
            <div role='columnheader' className={`${classNameSessionTableHeaderLeft} pb-8 pl-5 flex items-center`}>
              <span
                className={`${classNameEventDetailsDate} text-primary font-size-14px ${Styles.headerCssClass} uppercase flex justify-start md:justify-end mr-3`}
              >
                {selectedDate}
              </span>
              { isSessionsExist
                ? (
                  <span
                    style={{
                      fontSize: '10px',
                      lineHeight: '1.2rem',
                    }}
                    className={classNameSessionHeaderRight + 'flex whitespace-no-wrap'}
                  >
                    {`${sessions} ${sessions === count ? 'session' : 'sessions'}`}
                  </span>
                )
                : null
              }
            </div>
          )
          : null
      }
      <div className='px-8'>
        {
          featuredMeetings.map((meeting: PrivateMeetingSessionDetailsResponseType) => (
            <CardComponent
              event={event}
              meeting={meeting}
              key={meeting.meetingId}
              eventToken={eventToken}
              transformedTimeZone={transformedTimeZone}
            />
          ))
        }
        {
          isCarouselViewByDay
            ? Boolean(featuredMeetings.length) && <hr className='invisible my-8 w-full' />
            : renderSeparateLine(Boolean(featuredMeetings.length))
        }
        <CarouselBox
          meetings={noFeaturedMeetings}
          event={event}
          groupByDay={isCarouselViewByDay}
          eventToken={eventToken}
          transformedTimeZone={transformedTimeZone}
        />
      </div>
    </div>
  );
};

const CarouselWrapperByTrack = (props: ICarouselWrapper): JSX.Element => {
  const {
    event,
    meetings,
    selectedDate,
    showPastMeetings,
    searchedValue,
    setFilteredMeetingsCount,
    eventToken,
    transformedTimeZone
  } = props;

  const {
    featuredMeetings,
    trackMeetings,
    noGroupMeetings
  } = useCarousel({ meetings, selectedDate, showPastMeetings, searchedValue });

  useEffect(() => {
    setFilteredMeetingsCount(featuredMeetings.length + trackMeetings.length + noGroupMeetings.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredMeetings.length, trackMeetings.length, noGroupMeetings.length]);

  return (
    <div>
      <div className='px-8'>
        {
          featuredMeetings.map((meeting: PrivateMeetingSessionDetailsResponseType) => (
            <CardComponent
              event={event}
              meeting={meeting}
              key={meeting.meetingId}
              eventToken={eventToken}
              transformedTimeZone={transformedTimeZone}
            />
          ))
        }
        { renderSeparateLine(Boolean(featuredMeetings.length)) }
        <CarouselBox meetings={trackMeetings} event={event} eventToken={eventToken} transformedTimeZone={transformedTimeZone} />
        <CarouselBox meetings={noGroupMeetings} event={event} eventToken={eventToken} transformedTimeZone={transformedTimeZone} />
      </div>
    </div>
  );
};

export { CarouselWrapperByDay, CarouselWrapperByTrack };
