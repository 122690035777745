import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { isSameDay } from 'date-fns';
import useSetKeyIdClass from '../../../../hooks/use-set-key-id-class';
import {
  getFullDateInUsEuFormat,
  dateToFormattedString,
  DateFnsFormats,
  getFormatTimezone,
  getTimeInLocalFormat,
  getIsEnUsRegion
} from '../../../../lib/helpers/dateHelper';
import { GetMeetingStatus } from '../../../shared/MeetingTimingBlock';

export interface IProps {
  meetingDetails: {
    startDateTime: string | Date,
    endDateTime: string | Date
  }
  timeZone: string,
  featured: boolean;
  hideStartDate?: boolean,
  hideEndDateTime?: boolean,
  isMultiMeetingsBlock?: boolean
  transformedTimeZone: string;
}

const TimingBlock: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { featured, timeZone, meetingDetails, transformedTimeZone } = props;
  const { startDateTime, endDateTime } = meetingDetails;
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedHostStartTime = getTimeInLocalFormat(startDateTime, timeZone);
  const formattedAttendeeStartTime = getTimeInLocalFormat(startDateTime, currentTimeZone);
  const attendeeStartDateTime = utcToZonedTime(startDateTime, currentTimeZone);
  const attendeeOffsetGMT = getFormatTimezone(attendeeStartDateTime);
  const formattedHostEndTime = getTimeInLocalFormat(endDateTime, timeZone);
  const formattedAttendeeEndTime = getTimeInLocalFormat(endDateTime, currentTimeZone);
  const attendeeEndDateTime = utcToZonedTime(endDateTime, currentTimeZone);
  const eventStartDateTime = new Date(startDateTime);

  const className = 'editable-session';
  const classNameSessionTableRowLeftDate = `${className}--table-row-left-date`;
  const headerCssClass = 'font-bold text-left ';

  const timeDirection = featured ? 'flex-row flex-wrap pt-px font-size-12px' : 'pt-1 flex-row flex-wrap font-size-10px text-gray-700';
  const classNameEventDetails = 'editable-' + useSetKeyIdClass() + '-event-details';
  const classNameEventDetailsDate = `${classNameEventDetails}--date ${featured ? 'font-size-14px': 'font-size-12px'}`;
  const contentDateStyles = transformedTimeZone ? 'block' : 'flex md:text-right';
  const startTimeStyles = transformedTimeZone ? 'ml-0' : '';

  const fullDate = featured
    ? getFullDateInUsEuFormat(startDateTime, timeZone)
    : dateToFormattedString(startDateTime, getIsEnUsRegion() ? DateFnsFormats.StringifiedUSShortDay : DateFnsFormats.StringifiedEUShortDay, timeZone);
  const fullDateInLocalFormat = featured
    ? getFullDateInUsEuFormat(eventStartDateTime, currentTimeZone)
    : dateToFormattedString(eventStartDateTime, getIsEnUsRegion() ? DateFnsFormats.StringifiedUSShortDay : DateFnsFormats.StringifiedEUShortDay, currentTimeZone);
  const isEventTimezoneTimeDifferents = (): boolean => {
    return (getTimeInLocalFormat(attendeeStartDateTime, currentTimeZone) === getTimeInLocalFormat(eventStartDateTime, timeZone));
  };

  return (
    <div className='py-1 '>
      <p
        title={fullDate}
        className={`${classNameEventDetailsDate} text-primary ${headerCssClass} ${contentDateStyles} leading-6 justify-start overflow-hidden mb-5px flex-wrap`}
      >
        <span className='mr-1 inline-block'>
          {fullDate}
        </span>
        <span className={`${classNameSessionTableRowLeftDate} ${startTimeStyles} text-left text-black lowercase tracking-normal mr-1 inline-block`}>
          {formattedHostStartTime}{props.hideEndDateTime || ` - ${formattedHostEndTime}`}
        </span>
        {!!transformedTimeZone &&
          <span
            className={`${classNameSessionTableRowLeftDate} text-left text-black normal-case word-break inline`}
          >
            {transformedTimeZone}
          </span>
        }
      </p>
      {
        !isSameDay(eventStartDateTime, utcToZonedTime(startDateTime, timeZone)) || !isEventTimezoneTimeDifferents()
          ? (
            <p className={`${timeDirection}`}>
              {!isSameDay(eventStartDateTime, utcToZonedTime(startDateTime, timeZone)) &&
                <span title={fullDate} className='pr-1'>
                  {fullDateInLocalFormat}
                </span>}
              {!isEventTimezoneTimeDifferents() && <>
                <span className='lowercase'>
                  {formattedAttendeeStartTime}{props.hideEndDateTime || ` - ${formattedAttendeeEndTime}`}
                </span>
                &nbsp;({attendeeOffsetGMT})
              </>}
            </p>
          )
          : null
      }
      <GetMeetingStatus
        attendeeStartDateTime={attendeeStartDateTime}
        attendeeEndDateTime={attendeeEndDateTime}
        isMultiMeetingsBlock={props.isMultiMeetingsBlock}
      />
    </div>
  );
};

export { TimingBlock };