import React from 'react';
import { useHistory } from 'react-router-dom';
import { EventPrivateSettingsType } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import MeetingTimingBlock from '../../shared/MeetingTimingBlock';
import { EventPrivateDetailsResponseType, PrivateMeetingDetailsResponseType } from '../../../lib/api';
import OrganizationsList from '../../shared/OrganizationsList';
import useConnectMeetingDisabled from '../../../hooks/use-connect-meeting-disabled';
import Buttons from "../../../components/button";
import './MeetingBlock.scss';
import useMeetingCalendar from '../../../hooks/use-meeting-calendar';
import { MediaQueryContextType, useMediaQuery } from '../../../lib/context-providers/media-query-provider';

interface IProps {
  meeting: PrivateMeetingDetailsResponseType;
  eventToken: string;
  eventInfo: EventPrivateDetailsResponseType & EventPrivateSettingsType;
  transformedTimeZone: string;
}

const MeetingBlock: React.FC<IProps> = (props: IProps): JSX.Element => {

  const {
    notes,
    topic,
    type,
    access,
    meetingId,
    startDateTime,
    presenterOrganizations,
    participantOrganizations
  } = props.meeting;
  const {
    timeZone,
    eventId,
    name,
    eventTitleForPassport,
    topicEnabled
  } = props.eventInfo;

  const { fetchOutlookCalendarForMeeting, fetchGoogleCalendarForMeeting } = useMeetingCalendar();
  const isConnectBtnDisabled = useConnectMeetingDisabled(props.eventInfo, startDateTime);
  const history = useHistory();
  const className = 'editable-agenda';
  const classNameAgendaBlock = `${className}--block`;
  const classNameAgendaBlockDate = `${className}--block-date`;
  const classNameAgendaBlockAttendees = `${className}--block-attendees`;
  const classNameAgendaBlockButton = `${className}--block-button`;
  const presenterOrgName = presenterOrganizations?.length ? presenterOrganizations[0].name : '';
  const ariaLabelToJoinButton = topic
    ? topic
    : presenterOrgName;
  interface MeetingTopicProps {
    className?: string;
    access?: string;
  }

  const { isMobile, isDesktop } = useMediaQuery() as MediaQueryContextType;

  let timingBlockStyles = '';

  if (isDesktop) {
    timingBlockStyles = 'w-4/12';
  }

  if (!isMobile && !isDesktop) {
    timingBlockStyles = 'w-6/12';
  }

  const BulletIcon = () => <span className='bullet'>&#x25CF;</span>;

  const MeetingTopic = ({ className = '', access }: MeetingTopicProps) => {
    return (
      <div className={`word-break ${className}`} role={'heading'} aria-level={3}>
        {topic
          ? <h3>{topic}</h3>
          : (access !== 'CLOSED') && (presenterOrganizations?.length)
            ? presenterOrganizations.map(o => o.name).join(', ')
            : null}
      </div>
    );
  };

  const ShowOrganizations = () => {
    return (
      <>
        {presenterOrganizations && <OrganizationsList blockName='' organizations={presenterOrganizations} />}
        {participantOrganizations &&
          <OrganizationsList
            blockName={presenterOrganizations ? 'Attendees:' : ''}
            organizations={participantOrganizations}
          />
        }
      </>
    );
  };

  const PlenaryMeetingDescription = () => {
    return (
      <div className={`${classNameAgendaBlockAttendees} pr-5 sm:pr-8`}>
        <div className='flex font-bold mt-2'>
          {(topic || presenterOrganizations?.length) ? <BulletIcon /> : null}
          <MeetingTopic />
        </div>
        {notes && <p className='font-size-14px mt-1 pl-5 h-full break-words overflow-hidden' dangerouslySetInnerHTML={{ __html: notes }} />}
      </div>);
  };

  const MeetingDescription = () => {

    return access !== 'CLOSED'
      ? <PlenaryMeetingDescription />
      : <div className={`${classNameAgendaBlockAttendees}`}>
        {topicEnabled && <MeetingTopic className='font-medium font-size-18px mt-2 sm:mx-5' access={access} />}
        <ShowOrganizations />
      </div>;
  };

  return (<>
    <div className={`${classNameAgendaBlock} flex flex-row justify-between px-5 bg-cool-gray-hover`} data-meeting-id={meetingId}>
      <div className={`${classNameAgendaBlockDate} sm:flex w-full`}>
        <div className={`pr-5 flex-grow-0 flex-shrink-0 ${timingBlockStyles}`}>
          <MeetingTimingBlock
            transformedTimeZone={props.transformedTimeZone}
            meetingDetails={props.meeting}
            timeZone={timeZone}
            hideStartDate={true}
          />
          {type && <p className='text-sm'>
            <span className='text-primary text-xs font-bold'>Meeting type:&nbsp;</span>
            <span className='text-xs'>{type}</span>
          </p>}
        </div>
        <MeetingDescription />
      </div>

      <div className={`text-right mt-2`}>
        <div className='button-block'>
          <Buttons.OptionsButton
            label={'+ Add to Calendar'}
            name={'addToCalendar'}
            disabled={false}
            eventId={`${eventId}` || ''}
            eventName={name || ''}
            className='select-box--button-small'
            downloadIcsFile={()=> fetchOutlookCalendarForMeeting(`${eventId}`, meetingId, `${eventTitleForPassport || name}.ics`, props.eventToken)}
            openGoogleCalendar={()=> fetchGoogleCalendarForMeeting(`${eventId}`, meetingId, props.eventToken)}
            iconButton
          />
          <Buttons.Common
            name='meetingItem'
            data-meeting-id={meetingId}
            onClick={() => { history.push(`/agenda/${`${eventId}`}/meeting/${meetingId}`); }}
            label={isConnectBtnDisabled ? 'View Details' : 'Join'}
            className={`${classNameAgendaBlockButton} px-2 lg:px-6 py-3 rounded ${isConnectBtnDisabled ? 'bg-white outline outline-mid-gray font-medium text-primary' : 'on-hover-shadow bg-primary text-white'}`}
            ariaLabel={isConnectBtnDisabled ? 'View Meeting Details' : `Join ${ariaLabelToJoinButton}`}
          />
        </div>
      </div>
    </div>
    <hr />
  </>);
};

export default MeetingBlock;
