/*eslint-disable @typescript-eslint/no-var-requires*/
import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Switch, useLocation } from 'react-router-dom';
import { LayoutConfig } from '../config';
import { RoutesConfig } from '../config/routes';
import Layout from '../components/_base/Layout';
import { REGISTRATION_PATH, ROOT } from '../config/routes/paths';
import { useRoutes } from '../hooks/use-custom-routes';
import { IAuthContext, useAuth } from '../lib/context-providers/auth-context';
import { getUrlQueryArgByKey } from '../lib/api/utils';
import { BYPASS_TOKEN_KEY, ERROR_SUBDOMAIN, IDP_IDENTIFIER, ML_ACCESS_TOKEN } from '../lib/constants';
import { AppConfigurator } from '../lib/services/app-configuration/AppConfigurator';
import useDefaultRoutePage from "../hooks/use-default-route-page";
import useSubdomain from '../hooks/use-subdomain';
import { ErrorPage } from './passport/ErrorPage';
import { EventSettingsContextType, useEventSettings } from '../lib/context-providers/event-settings-context/event-settings-context';
import { AccountContextType, useAccountContext } from '../lib/context-providers/account-context';
import { getFirstPathSegmentFromLocation, getPathSegmentListFromLocation, normalizeRoutePath } from '../lib/helpers/urlHelper';
import { IEventCustomSettings, useCustomSettings } from '../hooks/use-custom-settings';

const { Route } = (() => {
  if (AppConfigurator.isPassportProfile) return require('../config/overrides/route');
  if (AppConfigurator.isMeetLinksProfile) return require('react-router-dom');
  if (AppConfigurator.isFileDownloadProfile) return require('react-router-dom');
})();

type Props = {
  routes: RoutesConfig
}

const RegistrationFormRoute = () => {
  const data = useEventSettings();
  const auth = useAuth() as IAuthContext;

  const accContext = useAccountContext() as AccountContextType;
  const noTokensInUrl = [ML_ACCESS_TOKEN, IDP_IDENTIFIER, BYPASS_TOKEN_KEY].every(v => !getUrlQueryArgByKey(v));
  const registrationAsLandingPage = data && data.registrationAsLandingPage;

  const isRootPath = (): boolean => {
    const pathSegments = getPathSegmentListFromLocation();
    if (accContext.isAccountSubdomain) {
      return pathSegments.length === 1;
    } else {
      return pathSegments.length === 0;
    }
  };

  const path = getFirstPathSegmentFromLocation();
  const isRedirectedToRegistration = auth?.isAuthenticated === false && !auth.isLoading && noTokensInUrl && isRootPath() && registrationAsLandingPage;
  const subdomain = useSubdomain() || '';
  const isRedirectedToErrorPage = subdomain === ERROR_SUBDOMAIN;

  if (isRedirectedToErrorPage) {
    return <ErrorPage/>;
  }
  if (auth?.ssoError) {
    return <Redirect to={accContext.isAccountSubdomain ? normalizeRoutePath(ROOT, path) : ROOT} />;
  }
  if (isRedirectedToRegistration) {
    return <Redirect to={accContext.isAccountSubdomain ? normalizeRoutePath(REGISTRATION_PATH, path) : REGISTRATION_PATH} />;
  }
  return null;
};

const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.getElementsByClassName('router-wrapper')?.[0] as HTMLDivElement | undefined;
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'instant' as ScrollBehavior,
      });
    }
  }, [pathname]);

  return null;
};

const RedirectToLandingPage = () => {
  const landingPage = useDefaultRoutePage();
  const location = useLocation();

  return (
    <Redirect
      to={{
        pathname: landingPage,
        state: { from: location.pathname },
        search: location.search
      }}
    />
  );
};

const PassportAppRouter: React.FC<Props> = (): JSX.Element => {
  const routes: RoutesConfig = { ...useRoutes() };
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const customSettings = useCustomSettings() as IEventCustomSettings;

  return (
    <BrowserRouter>
      <Layout {...LayoutConfig} routesConfig={routes} customPages={eventSettings?.customPages} customSettings={customSettings}>
        <ScrollToTop/>
        <RegistrationFormRoute/>
        { !!Object.keys(routes).length && (
          <Switch>
            {Object.keys(routes).map(path => <Route key={path} {...{ path, ...routes[path] }} />)}
            <Route key='no-match'>
              <RedirectToLandingPage />
            </Route>
          </Switch>
        )}
      </Layout>
    </BrowserRouter>
  );
};

/* used for meet links and file download apps (without Cognito authentication) */
const PublicAppRouter: React.FC<Props> = ({ routes }: Props): JSX.Element => {
  return (
    <BrowserRouter>
      <Layout {...LayoutConfig} routesConfig={routes}>
        <Switch>
          {Object.keys(routes).map(path => <Route key={path} {...{ path, ...routes[path] }} />)}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default AppConfigurator.isPassportProfile ? PassportAppRouter : PublicAppRouter;
